import { type ComponentPublicInstance, onMounted, onUnmounted, ref } from 'vue'

import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useIntersectionObserver } from '@vueuse/core'

interface Options {
  isBatchingEnabled?: boolean
  interval?: number
}

export function useImpressionTracker(
  // TODO: replace any by the signature of EventData => void or EventData[] => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tracker: (data: any) => void,
  options: Options = { isBatchingEnabled: false, interval: 1000 },
) {
  const trackingData = new Map<HTMLElement, EventData>()
  const stopIntersectionObserver = ref<() => void>()
  const bucket: Set<EventData> = new Set()

  let interval: NodeJS.Timeout | string | number | undefined

  onMounted(() => {
    const targets = Array.from(trackingData.keys())
    const { stop } = useIntersectionObserver(
      targets,
      (entries) => {
        for (const entry of entries) {
          const data = trackingData.get(entry.target as HTMLElement)
          if (entry.isIntersecting && data) {
            if (options.isBatchingEnabled) {
              bucket.add(data)
            } else {
              tracker(data)
            }
          }
        }
      },
      { threshold: 0.8 },
    )
    stopIntersectionObserver.value = stop

    if (options.isBatchingEnabled) {
      interval = setInterval(() => {
        if (bucket.size > 0) {
          tracker(Array.from(bucket))
          bucket.clear()
        }
      }, options.interval)
    }
  })

  onUnmounted(() => {
    if (stopIntersectionObserver.value) {
      stopIntersectionObserver.value()
    }
    clearInterval(interval)
    trackingData.clear()
  })

  /**
   * Use is in the template as follow
   * :ref="setupViewportTracking(data)"
   *
   * @param data a serializable JSON object
   */
  function setupImpressionTracker(data: EventData) {
    return function setTrackingRef(
      el: Element | ComponentPublicInstance | null,
    ) {
      if (el && el instanceof HTMLElement) {
        trackingData.set(el, data)
      }

      return el
    }
  }

  return { setupImpressionTracker }
}
