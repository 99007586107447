<template>
  <div>
    <div class="relative flex h-72 items-center justify-center p-16">
      <RevButtonRounded
        :alternative-text="i18n(translations.close)"
        class="absolute left-16 top-16"
        :icon="IconCross"
        variant="secondary"
        @click="close"
      />

      <span class="body-1">
        {{ i18n(translations.titleSort) }}
      </span>

      <div
        v-if="sort !== sortOptions[0].value"
        class="absolute right-16 top-16"
      >
        <RevButtonTiny variant="primary" @click="reset">
          {{ i18n(translations.erase) }}
        </RevButtonTiny>
      </div>
    </div>
    <div class="px-20">
      <ul class="rounded-md flex flex-col gap-16">
        <li v-for="sortOption in sortOptions" :key="sortOption.value">
          <RevRadio
            :id="`sort-${sortOption.value}`"
            v-model="sort"
            :value="sortOption.value"
            variant="full"
          >
            <template #label>
              <span>
                {{ sortOption.label }}
              </span>
            </template>
          </RevRadio>
        </li>
      </ul>
    </div>
    <div class="fixed inset-x-20 bottom-20">
      <RevButton class="w-full" variant="primary" @click="handleSeeAll">
        {{ i18n(translations.validate, { count: searchNbResults }) }}
      </RevButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevRadio } from '@ds/components/Radio'
import { IconCross } from '@ds/icons/IconCross'

import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import translations from './MobileFilters.translations'

const { searchNbResults, sortOptions } = defineProps<{
  searchNbResults: number
  sortOptions: {
    label: string
    value: string
  }[]
}>()

const sort = defineModel<string>('sort', { required: true })

const { close } = useFullscreenFilters()

const i18n = useI18n()
const { trackClick } = useTracking()

const reset = () => {
  sort.value = sortOptions[0].value

  trackClick({
    zone: 'mobileFilter',
    name: 'reset_sort',
  })
}

watch(sort, (newSort) => {
  trackClick({
    name: newSort,
    zone: 'mobileSort',
  })
})

function handleSeeAll() {
  close()

  trackClick({
    zone: 'mobileSort',
    name: 'see_results',
  })
}
</script>
