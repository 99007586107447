export default {
  filter: {
    id: 'landing_page_filter',
    defaultMessage: 'Filter',
  },
  sort: {
    id: 'landing_page_sort',
    defaultMessage: 'Sort',
  },
}
