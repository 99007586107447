<template>
  <div>
    <div v-if="selectedFacet === undefined">
      <div class="relative flex h-72 items-center justify-center p-16">
        <RevButtonRounded
          :alternative-text="i18n(translations.close)"
          class="absolute left-16 top-16"
          :icon="IconCross"
          variant="secondary"
          @click="close"
        />

        <span class="body-1">
          {{ i18n(translations.titleFilter) }}
        </span>

        <div v-if="isAnyFacetFiltered" class="absolute right-16 top-16">
          <RevButtonTiny variant="primary" @click="resetAllFacets">
            {{ i18n(translations.erase) }}
          </RevButtonTiny>
        </div>
      </div>
      <div class="px-20">
        <RevList :has-external-borders="false">
          <RevListItemInteractive
            v-if="priceFacet"
            @click="selectFacet(priceFacet)"
          >
            <template #label>
              {{ priceFacet.title }}
            </template>

            <template #description>
              <div
                class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {{
                  price[0] > 0 || price[1] !== priceFacet.max
                    ? `${i18n.price({ amount: price[0].toString(), currency: defaultCurrency })}, ${i18n.price({ amount: price[1].toString(), currency: defaultCurrency })}`
                    : ''
                }}
              </div>
            </template>

            <template #suffix>
              <IconChevronRight />
            </template>
          </RevListItemInteractive>
          <RevListItemInteractive
            v-for="facet in facets"
            :key="facet.name"
            @click="selectFacet(facet)"
          >
            <template #label>
              {{ facet.title }}
            </template>

            <template #description>
              <div
                class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {{ formatValues(facet.name) }}
              </div>
            </template>

            <template #suffix>
              <IconChevronRight />
            </template>
          </RevListItemInteractive>
        </RevList>
      </div>
    </div>
    <div v-else-if="selectedFacet.name === 'price' && priceFacet">
      <div class="relative flex h-72 items-center justify-center p-16">
        <RevButtonRounded
          :alternative-text="i18n(translations.close)"
          class="absolute left-16 top-16"
          :icon="IconArrowLeft"
          variant="secondary"
          @click="back"
        />

        <span class="body-1">
          {{ priceFacet.title }}
        </span>

        <div
          v-if="price[0] > 0 || price[1] !== priceFacet.max"
          class="absolute right-16 top-16"
        >
          <RevButtonTiny variant="primary" @click="resetPriceFacet">
            {{ i18n(translations.erase) }}
          </RevButtonTiny>
        </div>
      </div>
      <div class="px-20">
        <PriceFilter
          v-model="price"
          :avgPrice="priceFacet.avg"
          :currency="defaultCurrency"
          :max="priceFacet.max"
          :prices="priceFacet.values"
          :step="priceFacet.step"
          variant="full"
        />
      </div>
    </div>
    <div v-else>
      <div>
        <div class="relative flex h-72 items-center justify-center p-16">
          <RevButtonRounded
            :alternative-text="i18n(translations.close)"
            class="absolute left-16 top-16"
            :icon="IconArrowLeft"
            variant="secondary"
            @click="back"
          />

          <span class="body-1">
            {{ selectedFacet.title }}
          </span>

          <div
            v-if="isFiltered(selectedFacet)"
            class="absolute right-16 top-16"
          >
            <RevButtonTiny variant="primary" @click="resetFacet(selectedFacet)">
              {{ i18n(translations.erase) }}
            </RevButtonTiny>
          </div>
        </div>
      </div>
      <div class="px-20">
        <FacetFilter
          v-if="isStandardFacet(selectedFacet)"
          v-model="filters[selectedFacet.name]"
          :disabled="false"
          :name="selectedFacet.name"
          :title="selectedFacet.title"
          tracking="mobileFilter"
          :type="selectedFacet.type"
          :values="selectedFacet.values"
          variant="full"
        />
      </div>
    </div>
    <div class="fixed inset-x-20 bottom-20">
      <RevButton class="w-full" variant="primary" @click="handleSeeAll">
        {{ i18n(translations.validate, { count: searchNbResults }) }}
      </RevButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconCross } from '@ds/icons/IconCross'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import translations from './MobileFilters.translations'
import FacetFilter from './Refinements/FacetFilter.vue'
import PriceFilter from './Refinements/PriceFilter.vue'

const { searchNbResults, facets, priceFacet } = defineProps<{
  searchNbResults: number
  facets: Facet[]
  priceFacet: UiPriceFacet | undefined
}>()

const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})

const { close } = useFullscreenFilters()
const { trackClick } = useTracking()

const i18n = useI18n()
const { defaultCurrency } = useMarketplace().market

const selectedFacet = ref<Facet | UiPriceFacet | undefined>()

function selectFacet(facet: Facet | UiPriceFacet) {
  selectedFacet.value = facet
}

function back() {
  selectedFacet.value = undefined
}

function formatValues(facetName: string) {
  const filter = filters.value?.[facetName]
  if (Array.isArray(filter)) {
    const filterValueWithoutPrefix = filter.map((value) =>
      value.split(' ').slice(1).join(' '),
    )

    return filterValueWithoutPrefix.join(', ')
  }
  if (typeof filter === 'string') {
    return filter
  }

  return ''
}

function isStandardFacet(
  facet: Facet | UiPriceFacet | undefined,
): facet is Facet {
  return (
    facet !== undefined && facet.name !== undefined && facet.name !== 'price'
  )
}

function isFiltered(facet: Facet | UiPriceFacet | undefined): facet is Facet {
  if (isStandardFacet(facet)) {
    const filter = filters.value[facet.name]

    return filter.length > 0
  }

  return false
}

const isAnyFacetFiltered = computed(() => {
  return (
    price.value[0] !== 0 ||
    price.value[1] !== priceFacet?.max ||
    Object.values(filters.value).some((val) =>
      Array.isArray(val) ? val.length !== 0 : !val,
    )
  )
})

function resetPriceFacet() {
  price.value[0] = 0
  price.value[1] = priceFacet?.max ?? 0

  trackClick({
    zone: 'mobileFilter',
    name: 'reset_filter',
  })
}

function resetFacet(facet: Facet) {
  if (isStandardFacet(facet)) {
    trackClick({
      zone: 'mobileFilter',
      name: 'reset_filter',
    })

    filters.value[facet.name] = []

    trackClick({
      zone: 'mobileFilter',
      name: 'reset_filter',
    })
  }
}

function resetAllFacets() {
  for (const facet of facets) {
    resetFacet(facet)
  }
  resetPriceFacet()

  trackClick({
    zone: 'mobileFilter',
    name: 'reset_filter',
  })
}

function handleSeeAll() {
  close()

  trackClick({
    zone: 'mobileFilter',
    name: 'see_results',
  })
}
</script>
