export default {
  close: {
    id: 'filters_close',
    defaultMessage: 'Close',
  },
  titleFilter: {
    id: 'filters_title',
    defaultMessage: 'Filter',
  },
  titleSort: {
    id: 'landing_page_sort',
    defaultMessage: 'Sort',
  },
  price: {
    id: 'filters_price',
    defaultMessage: 'Price',
  },
  validate: {
    id: 'filters_results',
    defaultMessage: `See {count, plural,
      one {# product}
      other {# products}
    }`,
  },
  erase: {
    id: 'filters_erase',
    defaultMessage: 'Erase',
  },
  toFacets: { id: 'product_filter_to_facets', defaultMessage: 'Back' },
}
