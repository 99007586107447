export default {
  cta: {
    id: 'highlighted_product_card_cta',
    defaultMessage: 'Check it out',
  },
  pillDiscountPercentage: {
    id: 'highlighted_product_card_pill_discount_percentage',
    defaultMessage: '{percent}%',
  },
  pillDiscountBody: {
    id: 'highlighted_product_card_pill_discount_body',
    defaultMessage: 'less than new',
  },
  explanationTitle: {
    id: 'highlighted_product_card_explanation_title',
    defaultMessage: 'Staff pick',
  },
  explanationBody: {
    id: 'highlighted_product_card_explanation_body',
    defaultMessage: 'Our latest and most favorite offer.',
  },
  startingFrom: {
    id: 'highlighted_product_card_starting_from',
    defaultMessage: 'Starting from',
  },
  notDefinedAttribute: {
    id: 'highlighted_product_card_list_view_not_defined_attribute',
    defaultMessage: 'ND',
  },
  productCardWarranty: {
    id: 'product_card_warranty',
    defaultMessage: 'Warranty: {warranty}',
  },
  months: {
    id: 'landing_months',
    defaultMessage: 'months',
  },
}
