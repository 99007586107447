<template>
  <div>
    <RevInputSelect
      id="sort"
      v-model="sortInput"
      data-qa="sort-by-desktop"
      :disabled="!ready"
      :label="i18n(translations.sort)"
      :options="sortOptions"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevInputSelect } from '@ds/components/InputSelect'

import translations from './SortInput.translations'

const { trackClick } = useTracking()
const i18n = useI18n()

defineProps<{
  sortOptions: {
    label: string
    value: string
  }[]
}>()

const sortInput = defineModel<string>({ required: true })

const ready = ref(false)

watch(sortInput, (newSortInput) => {
  trackClick({
    name: newSortInput,
    zone: 'product list > sort',
  })
})

onMounted(() => {
  ready.value = true
})
</script>
