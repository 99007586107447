import { useState } from '#imports'
import { watch } from 'vue'

export function useFullscreenFilters() {
  const displayed = useState('plp-fullscreen-filters-display', () => false)
  const type = useState<'filter' | 'sort'>(
    'plp-fullscreen-filters-type',
    () => 'filter',
  )

  function filter() {
    type.value = 'filter'
    displayed.value = true
  }
  function sort() {
    type.value = 'sort'
    displayed.value = true
  }
  function close() {
    displayed.value = false
  }

  watch(displayed, (newDisplayed) => {
    if (newDisplayed) {
      window.document.body.style.overflow = 'hidden'
      window.document.body.style.height = '100%'
    } else {
      document.body.style.overflow = ''
      document.body.style.height = ''

      window.scrollTo(0, 300)
    }
  })

  return { displayed, type, filter, sort, close }
}
