/* eslint-disable no-param-reassign */
import { type Ref, type ShallowReactive, onMounted, watch } from 'vue'
import {
  type RouteLocationNormalizedLoaded,
  type Router,
  useRoute,
  useRouter,
} from 'vue-router'

import { parse } from '@backmarket/utils/query-string/parse'
import { stringify } from '@backmarket/utils/query-string/stringify'

export function useFiltersFromHash(
  filtersInput: ShallowReactive<Record<string, string[]>>,
  priceInput: Ref<[number, number]>,
  pageInput: Ref<number>,
  {
    route = useRoute(),
    router = useRouter(),
  }: {
    route?: Partial<RouteLocationNormalizedLoaded>
    router?: Partial<Router>
  } = {},
) {
  const initMaxPrice = priceInput.value[1]
  function getFiltersFromUrl() {
    const { price, ...parsedQuery } = route.hash
      ? parse(route.hash)
      : { price: [] }

    const p = route.query
      ? parseInt(route.query.p?.toString() || '', 10) || 0
      : 0

    if (Array.isArray(price) && price.length === 2 && price[0] && price[1]) {
      priceInput.value = [parseInt(price[0], 10), parseInt(price[1], 10)]
    }
    for (const [key, val] of Object.entries(parsedQuery)) {
      if (Array.isArray(val)) {
        filtersInput[key] = val.filter((item) => item !== null) as string[]
      } else if (val !== null) {
        filtersInput[key] = [val]
      }
    }

    if (p > 0) {
      pageInput.value = p
    }
  }

  function pushHashFiltersToRouter() {
    pageInput.value = 0
    const filters =
      priceInput.value[0] !== 0 || priceInput.value[1] !== initMaxPrice
        ? { ...filtersInput, price: priceInput.value }
        : filtersInput
    const hashForActiveFilters = stringify(filters)

    if (router && router.push && route.name) {
      void router.push({
        name: route.name,
        query: { ...route.query, p: 0 },
        params: route.params,
        hash: `#${hashForActiveFilters}`,
      })
    }
  }
  function pushQueryFiltersToRouter() {
    if (router && router.push && route.name) {
      void router.push({
        name: route.name,
        query: { ...route.query, p: pageInput.value },
        params: route.params,
        hash: route.hash,
      })
      if (import.meta.client) window.scrollTo(0, 0)
    }
  }

  onMounted(() => {
    getFiltersFromUrl()
  })

  watch([filtersInput, priceInput], () => {
    pushHashFiltersToRouter()
  })
  watch(pageInput, () => {
    pushQueryFiltersToRouter()
  })
}
