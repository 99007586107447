<template>
  <RevInputText
    id="min-price"
    v-model="input"
    :disabled="!ready"
    :format="(value) => Math.ceil(Number(value))"
    :has-clear-button="false"
    :label="label"
    type="number"
  />
</template>

<script lang="ts" setup>
import { defineModel, onMounted, ref } from 'vue'

import { RevInputText } from '@ds/components/InputText'

defineProps<{
  label: string
}>()

const input = defineModel<number>()

const ready = ref(false)

onMounted(() => {
  ready.value = true
})
</script>
