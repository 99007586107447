export default {
  recapTitle: {
    id: 'search_recap_title',
    defaultMessage: 'Your search:',
  },
  resultsCount: {
    id: 'landing_page_filters_products_new_plural',
    defaultMessage: `{total, plural,
      one {# product}
      other {# products}
    }`,
  },
}
