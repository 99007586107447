<template>
  <div
    class="bg-static-default-low fixed top-0 z-20 h-screen w-full overflow-scroll pb-[11.2rem]"
  >
    <Filters
      v-if="type === 'filter'"
      v-model:filters="filters"
      v-model:price="price"
      :facets="facets"
      :price-facet="priceFacet"
      :search-nb-results="searchNbResults"
    />
    <Sort
      v-else
      v-model:sort="sort"
      :search-nb-results="searchNbResults"
      :sort-options="sortOptions"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import Filters from './Filters.vue'
import Sort from './Sort.vue'

const { searchNbResults } = defineProps<{
  searchNbResults: number
  sortOptions: {
    label: string
    value: string
  }[]
  facets: Facet[]
  priceFacet: UiPriceFacet | undefined
}>()

const sort = defineModel<string>('sort', { required: true })
const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})

const { type } = useFullscreenFilters()
</script>
