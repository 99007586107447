export default {
  deliveryTitle: {
    id: 'trust_list_delivery_title',
    defaultMessage: 'Free standard shipping',
  },
  warrantyTitle: {
    id: 'trust_list_warranty_title',
    defaultMessage: '1-year warranty',
  },
  returnTitle: {
    id: 'trust_list_return_title',
    defaultMessage: 'Free {days}-day returns',
  },
  reviewsTitle: {
    id: 'trust_list_reviews_title',
    defaultMessage: `{rate}/5 among {count, plural,
      one {# verified review}
      other {# verified reviews}
    } in the last 6 months`,
  },
}
