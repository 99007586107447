<template>
  <div class="flex flex-wrap items-center gap-8">
    <span
      class="text-static-default-hi body-2 inline-flex items-center justify-center md:bg-static-default-hi md:rounded-sm md:px-12 md:py-14 lg:py-6"
      data-selector="total-products"
      data-test="total-products"
    >
      {{
        i18n(translations.resultsCount, {
          total: searchNbResults,
        })
      }}
    </span>

    <template v-for="[name, values] in removableFilters" :key="name">
      <RevButtonTiny
        v-for="value in values"
        :key="value"
        class="hidden lg:block"
        :icon="IconCross"
        variant="secondary"
        @click="onRemoveFilter(name, value)"
      >
        {{ formatValue(value) }}
      </RevButtonTiny>
    </template>
    <RevLink
      v-if="showClearButtons"
      class="hidden px-12 py-4 lg:block"
      @click="onReset"
    >
      {{ i18n(translations.clearAllFilters) }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevLink } from '@ds/components/Link'
import { IconCross } from '@ds/icons/IconCross'

import type { Facet } from '../../search/composables/useProductsSearch'

import translations from './ActiveFilters.translations'

const i18n = useI18n()

const { trackClick } = useTracking()

const { facets, maxPrice } = defineProps<{
  facets: Facet[]
  searchNbResults: number
  maxPrice: number
}>()

const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})

const showClearButtons = computed(() => {
  return (
    facets.some((facet) => filters.value[facet.name].length > 0) ||
    price.value[0] !== 0 ||
    price.value[1] !== maxPrice
  )
})

const removableFilters = computed(() => {
  return Object.entries(filters.value).filter(([, value]) => value.length > 0)
})

function resetFilter(facetName: string, value: string) {
  filters.value[facetName] = filters.value[facetName].filter(
    (filter) => filter !== value,
  )
}

function onReset() {
  for (const facet of facets) {
    filters.value[facet.name] = []
  }
  price.value[0] = 0
  price.value[1] = maxPrice
  trackClick({
    name: 'clear_filter',
    zone: 'product list > side filter',
    value: 'clear all filters',
  })
}

function onRemoveFilter(facetName: string, value: string) {
  resetFilter(facetName, value)
  trackClick({
    name: 'clear_filter',
    zone: 'product list > side filter',
    value: facetName,
  })
}

function formatValue(value: string) {
  const parts = value.split(' ')

  return parts.length > 1 ? parts.slice(1).join(' ') : value
}
</script>
