export default {
  noResultTitle: {
    id: 'search_landing_no_results_title',
    defaultMessage: 'Aw, shucks!',
  },
  noResultDescription: {
    id: 'search_landing_no_results_description',
    defaultMessage:
      "We looked everywhere: the item you're looking for doesn't exist on our marketplace. Try your luck with another search or check out our great deals.",
  },
}
