export default {
  navigate: {
    id: 'pagination_navigate',
    defaultMessage: 'Navigate to page {page}',
  },
  pagination: {
    id: 'pagination_label',
    defaultMessage: 'Product list pagination',
  },
}
