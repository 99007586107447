export default {
  clearAllFilters: {
    id: 'landing-page_filters_clear',
    defaultMessage: 'Clear all filters',
  },
  resultsCount: {
    id: 'landing_page_filters_products_new_plural',
    defaultMessage: `{total, plural,
      one {# product}
      other {# products}
    }`,
  },
}
