<template>
  <RevPagination
    :current-page="currentPage"
    :current-page-label="`${currentPage}`"
    navigation-aria-label="pagination"
    :next-aria-label="i18n(translations.navigate, { page: currentPage + 1 })"
    :page-aria-label="(page) => i18n(translations.navigate, { page })"
    :page-count="pageCount"
    :previous-aria-label="
      i18n(translations.navigate, { page: currentPage - 1 })
    "
    @click="handlePagination"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevPagination } from '@ds/components/Pagination'

import translations from './Pagination.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

defineProps<{ pageCount: number }>()
const pageInput = defineModel<number>({ required: true })

const currentPage = computed(() => pageInput.value + 1)

function handlePagination({ page }: { page: number }) {
  pageInput.value = page - 1

  trackClick({
    name: page,
    zone: 'pagination',
  })
}
</script>
