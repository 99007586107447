<template>
  <div>
    <div
      v-for="({ height, inRange }, index) in data"
      :key="`${index}_${height}`"
      class="inline-block px-3"
      :style="{
        height: `${height}%`,
        width: `${100 / data.length}%`,
      }"
    >
      <div
        class="rounded-xs size-full"
        :class="{
          'bg-static-default-hi': !inRange,
          'bg-static-info-hi': inRange,
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  step: number
  minSelected: number
  maxSelected: number
  items: number[]
}>()

const maxCount = computed((): number => Math.max(...props.items))
const data = computed(() =>
  props.items.map((item, index) => {
    const min = props.step * index
    const max = props.step * (index + 1)

    return {
      inRange: props.minSelected < max && props.maxSelected > min,
      height: Math.ceil((item / maxCount.value) * 100),
    }
  }),
)
</script>
