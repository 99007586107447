import { useNuxtData } from '#imports'
import { ref } from 'vue'

import { type AlgoliaLink } from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import {
  type IndexSearch,
  getConfigurationByScope,
  getConfigurationByScopeId,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'
import {
  type SearchApiKey,
  getApiKey,
} from '@backmarket/http-api/src/api-specs-search-reco/search/searchKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

export function getHighlightedTitleFromSearch(suggestion: AlgoliaLink) {
  // eslint-disable-next-line no-underscore-dangle
  return suggestion._highlightResult?.title.value ?? ''
}

export async function useAlgoliaApiKey(indexType: string | undefined) {
  if (!indexType)
    return { data: ref(null), error: ref(new Error('no indextype available')) }
  const { data, error } = await useHttpFetch(getApiKey, {
    pathParams: { index: indexType },
    getCachedData: (key) => useNuxtData<SearchApiKey>(key).data.value,
  })

  return { data, error }
}

export async function useAlgoliaConfiguration(scope: string, id?: string) {
  const { data, error } = id
    ? await useHttpFetch(getConfigurationByScopeId, {
        pathParams: { scope, id },
      })
    : await useHttpFetch(getConfigurationByScope, {
        pathParams: { scope },
      })

  return {
    data,
    error,
  }
}

export function getSortOptions(indexes: {
  active: IndexSearch
  other?: IndexSearch[]
}) {
  const i18n = useI18n()

  return [indexes.active, ...(indexes?.other ?? [])].map((index) => {
    const label = i18n({ id: index.title, defaultMessage: index.title })

    return {
      label,
      value: index.name,
    }
  })
}
