<template>
  <RevCard
    class="flex flex-col gap-16 p-16 md:flex-row md:p-72"
    data-qa="search-landing-no-result"
    data-test="search-landing-no-result"
  >
    <div class="flex flex-1 flex-col items-center gap-16 md:items-start">
      <h1 class="heading-1">{{ i18n(translations.noResultTitle) }}</h1>
      <p class="body-1 text-center md:mb-16 md:text-left">
        {{ i18n(translations.noResultDescription) }}
      </p>
      <RevButton
        v-if="button"
        full-width="responsive"
        :rel="button.link?.rel"
        :target="button.link?.target"
        :to="button.link?.href"
        :tracking="{ name: 'search_no_result_cta' }"
        variant="primary"
      >
        {{ button.label }}
      </RevButton>
    </div>

    <div class="order-first flex items-center justify-center md:order-none">
      <RevIllustration
        :alt="`${i18n(translations.noResultTitle)}`"
        :height="165"
        src="/img/plp/IllustrationBanana.svg"
        :width="165"
      />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getPageContent } from '@backmarket/http-api/src/api-specs-content'
import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import { useFetchFormattedPageContent } from '~/scopes/cms/composables/useFetchFormattedPageContent'

import translations from './NoResultCard.translations'

const i18n = useI18n()

const { data } = useFetchFormattedPageContent(getPageContent, {
  pathParams: {
    pageType: 'landing-page-no-results',
    pageName: 'default',
  },
})

const button = computed(() => {
  if (data.value !== null) {
    const { primary } = data.value.blocks[0].props as { primary: CTALink }

    return { label: primary.label, link: primary.link }
  }

  return undefined
})
</script>
