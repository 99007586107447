<template>
  <RevStickyBar>
    <div class="flex">
      <div class="flex w-1/2 items-center justify-center">
        <RevButtonBase class="body-1-bold px-24 py-12" @click="handleFilter">
          <div class="flex">
            <span class="flex items-center gap-4">
              {{ i18n(translations.filter) }}
              <RevTag
                v-if="facetsCount"
                :label="`${facetsCount}`"
                variant="secondary"
              />
            </span>
            <IconEqualizer class="ml-12" />
          </div>
        </RevButtonBase>
      </div>

      <div
        class="border-l-static-default-low flex w-1/2 items-center justify-center border-l"
      >
        <RevButtonBase class="body-1-bold px-24 py-12" @click="handleSort">
          <div class="flex">
            {{ i18n(translations.sort) }}
            <IconSort class="ml-12" />
          </div>
        </RevButtonBase>
      </div>
    </div>
  </RevStickyBar>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevStickyBar } from '@ds/components/StickyBar'
import { RevTag } from '@ds/components/Tag'
import { IconEqualizer } from '@ds/icons/IconEqualizer'
import { IconSort } from '@ds/icons/IconSort'

import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import translations from './ActionStickyBar.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

defineProps<{
  facetsCount?: number
}>()

const { filter, sort } = useFullscreenFilters()

function handleFilter() {
  filter()
  trackClick({
    name: 'filter',
    zone: 'mobileFilter',
  })
}

function handleSort() {
  sort()
  trackClick({
    name: 'sort',
    zone: 'mobileSort',
  })
}
</script>
